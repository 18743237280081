import _laxios from '../../_laxios';

module.exports = {
	namespaced: true,
	actions: {
		fetchSubscribes({ rootGetters }, params) {
			let {'lang/id': langId} = rootGetters;
			Object.assign(params, { langId: langId });

			return _laxios
				.getSubscribePageInfo
				.request({ params })
				.then((response) => response)
				.catch((error) => {
					console.error('subscriptions/subspage fetchSubscribe error', error.message);
				});

		},
		deleteSubscribe(_, { projectId, channelId, subsId }) {

			return _laxios
				.deleteSubscribe
				.expandUrl({ projectId, channelId, subsId})
				.request()
				.then((response) => response);
		},
	},
};
