import _laxios from '../../_laxios';
import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		about: {}
	},
	actions: {
		about({rootGetters, commit}) {
			let {'lang/id': langId} = rootGetters;
			const params = {
				langId,
			};
			return _laxios.autotestCabinetAbout.request({params}).then((data) => {
				commit('about', data);
			});
		},
		orderSearch({rootGetters}, {type, status, limit, offset} = {}) {
			let {'lang/id': langId} = rootGetters;
			const params = {
				langId,
				type,
				status,
				limit,
				offset
			};
			return _laxios.autotestCabinetOrders.request({params});
		},
		saveAsShow({}, data) {
			return _laxios.saveAsShow.request({ data });
		},
		getAutotestDataVIN({}, params) {
			return _laxios.getAutotestDataVIN
				.request({ params })
				.then(payload => payload);
		},
	},
	mutations: {
		about(store, data) {
			Vue.set(store, 'about', data);
		},
	},
	getters: {
		statusFilter({about: {statusFilter}}) {
			return statusFilter;
		},
		status({about: {status} = {}}) {
			return status;
		},
		type({about: {type}}) {
			return type;
		}
	}
};
