import Vue from 'vue';
import _laxios from '../../_laxios';

const { cabinet: { advertisementStat } = {} } = __INITIAL_STATE__;

module.exports = {
  namespaced: true,
  state: {
    autoData: {},
    publications: {},
    openPhoneUsers: {},
    charts: {},
    periods: [7, 30, 90, 180],
    period: 30,
    top: {},
    notYourCar: false,
    ...advertisementStat,
  },
  actions: {
    autoData({ commit, rootGetters }, advertisementId) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;

      return _laxios.advertisementStat
        .expandUrl({ advertisementId })
        .request()
        .then((payload) => {
          commit('notYourCar', false);
          commit('autoData', { payload });
        })
        .catch((e) => {
          if (e.response.status === 403) {
            commit('notYourCar', true);
          }
          console.error('/cabinet/api/car/{advertisementId}/statistic/', e.message);
        });
    },
    charts({ commit, rootGetters }, advertisementId) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;

      return _laxios.advertisementStatCharts
        .expandUrl({ advertisementId })
        .request()
        .then((payload) => {
          delete payload['0000-00-00'];
          commit('notYourCar', false);
          commit('charts', { payload });
          return payload;
        })
        .catch((e) => {
          if (e.response.status === 403) {
            commit('notYourCar', true);
          }
          console.error('/cabinet/api/car/{advertisementId}/statistic/charts/', e.message);
        });
    },
    period({ commit, getters }, newValue) {
      const nextValue = getters.periods.reduce((prev, curr) => (prev >= newValue ? prev : curr));
      commit('period', nextValue);
    },
    publications({ commit, rootGetters }, { advertisementId, limit, skip }) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;

      const data = {
        params: {},
      };
      data.params = {
        limit,
        skip,
      };

      return _laxios.advertisementStatPublications
        .expandUrl({ advertisementId })
        .request(data)
        .then((payload) => {
          commit('notYourCar', false);
          commit('publications', { payload });
          return payload;
        })
        .catch((e) => {
          if (e.response.status === 403) {
            commit('notYourCar', true);
          }
          console.error('/cabinet/api/car/{advertisementId}/statistic/publications/', e.message);
        });
    },
    top({ commit, rootGetters }, { advertisementId, limit, skip }) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;

      const data = {
        params: {},
      };
      data.params = {
        limit,
        skip,
      };

      return _laxios.advertisementStatTop
        .expandUrl({ advertisementId })
        .request(data)
        .then((payload) => {
          commit('notYourCar', false);
          commit('top', { payload });
          return payload;
        })
        .catch((e) => {
          if (e.response.status === 403) {
            commit('notYourCar', true);
          }
          console.error('/cabinet/api/car/{advertisementId}/statistic/top/', e.message);
        });
    },
    openPhoneUsers({ commit }, {
      advertisementId, perPage, period, page, withInterval = 0,
    }) {
      const data = {
        params: {
          perPage,
          period,
          page,
          withInterval,
        },
      };

      return _laxios.advertisementStatOpenPhone
        .expandUrl({ advertisementId })
        .request(data)
        .then((payload) => {
          commit('notYourCar', false);
          commit('openPhoneUsers', payload);
          return payload;
        })
        .catch((e) => {
          if (e.response.status === 403) {
            commit('notYourCar', true);
          }
          console.error('/cabinet/api/car/{advertisementId}/statistic/openPhone/', e.message);
        });
    },
  },
  mutations: {
    autoData(state, { payload }) {
      Vue.set(state, 'autoData', payload);
    },
    charts(state, { payload }) {
      Vue.set(state, 'charts', payload);
    },
    period(state, value) {
      Vue.set(state, 'period', value);
    },
    publications(state, { payload }) {
      Vue.set(state, 'publications', payload);
    },
    top(state, { payload }) {
      Vue.set(state, 'top', payload);
    },
    notYourCar(state, value) {
      Vue.set(state, 'notYourCar', value);
    },
    openPhoneUsers(state, value) {
      Vue.set(state, 'openPhoneUsers', value);
    },
  },
  getters: {
    autoData({ autoData }) {
      return autoData;
    },
    charts({ charts }) {
      return charts || {};
    },
    periods({ periods }) {
      return periods;
    },
    period({ period }) {
      return period;
    },
    publications({ publications }) {
      return publications;
    },
    top({ top }) {
      return top;
    },
    notYourCar({ notYourCar }) {
      return notYourCar;
    },
    openPhoneUsers({ openPhoneUsers }) {
      return openPhoneUsers;
    },
  },
};
