import Vue from 'vue';
import { deleteAdvertisement, moveToArchiveAdvertisement, updatePriceForMovedArchiveAdvertisement } from '../../queries';

import filter from './filter';
import advertisement from './advertisement';
import popups from './popups';
import manage from './manage';
import _laxios from '../../_laxios';

const advertisementsQuery = require('../../_graphql/queries/advertisements');

module.exports = {
  namespaced: true,
  state: {
    page: 1,
    size: 20,
    advCount: 5,
    type: 'active',
    showLoader: {
      active: true,
      awaitForPayment: true,
      draft: true,
      archive: true,
    },
    exchangeRates: [],
    vinDataByUserId: {},
  },
  getters: {
    getAdvCount(state) {
      return state.advCount;
    },
    size({ size }) {
      return size;
    },
    page({ page }) {
      return page;
    },
    advertisements(state) {
      return state[state.type] || [];
    },
    advertisementsActive(state) {
      return state.active || [];
    },
    type({ type }) {
      return type;
    },
    showLoader({ type, showLoader }) {
      return showLoader[type];
    },
    getExchangeRates({ exchangeRates }) {
      return exchangeRates;
    },
    getVinDataByUserId({ vinDataByUserId }) {
      return vinDataByUserId;
    },
  },
  actions: {
    fetchVinDataByUserId({ commit }) {
      return _laxios.getVinCodeDataByUserId
        .request()
        .then((data) => {
          commit('setVinDataByUserId', { data });
          return data;
        })
        .catch((e) => {
          console.error('fetchVinDataByUserId:', e);
        });
    },
    setExchangeRates({ commit }) {
      return _laxios.exchangeRates
        .request()
        .then((data) => {
          commit('exchangeRates', data);
        })
        .catch((e) => {
          console.error('setExchangeRates:', e);
        });
    },
    moveToArchive({ commit }, {
      id, reason, price, source = 2,
    } = {}) {
      return moveToArchiveAdvertisement({
        id, reason, price: (price ? Number(price) : undefined), source,
      })
        .then((payload) => {
          commit('delete', { id, reason });
          return payload;
        })
        .catch((err) => {
          console.log('DEBUG: index 37', err);
          return err;
        });
    },
    updatePriceMovedToArchive({ commit }, { id, price, currency } = {}) {
      return updatePriceForMovedArchiveAdvertisement({ id, price, currency })
        .then((payload) => payload)
        .catch((err) => {
          console.log('DEBUG: updatePriceForMovedArchiveAdvertisement', err);
          return err;
        });
    },
    delete({ commit }, { id } = {}) {
      return deleteAdvertisement({ id })
        .then(() => {
          commit('delete', { id });
        })
        .catch((err) => {
          console.log('DEBUG: index 46', err);
          return err;
        });
    },
    fetch({ commit, getters, rootGetters }, filterData) {
      const { 'shared/url/current': url, 'lang/id': langId, 'cabinet/userInfo/_auth': _auth } = rootGetters;
      let { type, 'filter/sort': orderType, 'filter/query': query } = getters;

      if (!orderType && type === 'active') {
        orderType = 'levels';
      }

      if (type === 'archive') {
        orderType = 'createdAt';
      }

      const order = {
        'price.asc': { field: 'price', desc: false },
        'price.desc': { field: 'price', desc: true },
        'year.asc': { field: 'year', desc: false },
        'year.desc': { field: 'year', desc: true },
        'mileage.asc': { field: 'mileage', desc: false },
        'mileage.desc': { field: 'mileage', desc: true },
        'dates.created.asc': { field: 'publication.createdAt', desc: false },
        'dates.created.desc': { field: 'publication.createdAt', desc: true },
        levels: { field: 'levels' },
        createdAt: { field: 'createdAt', desc: true },
      }[orderType];

      const data = {
        query: advertisementsQuery,
        variables: {
          params: [
            {
              userId: _auth.userId,
              ...query,
              ...filterData,
            },
          ],
          searchType: type,
          limit: 2000,
          order,
          langId,
        },
      };

      return _laxios
        .graphql
        .request({ data })
        .then(({ data: { searchCommon: advertisements = [] } = {} } = {}) => {
          const ids = [];
          commit('setCountAdv', advertisements.length);
          for (const advertisement of advertisements) {
            if (advertisement) {
              ids.push(Number(advertisement.id));
            }
          }
          commit('setAdvertisements', { payload: ids, type });
          // commit('setLoaderValue', {type: getters.type, value: false});
        })
        .catch((e) => {
          console.error('GraphQL, vue/store/cabinet/advertisements/index.js:', e.message);
        });
    },
    setPage({ commit }, page) {
      commit('setPage', { page: Number(page) || 1 });
    },
    setType({ commit, rootState }, type) {
      commit('setType', { type });
    },
    setSize({ commit }, size) {
      commit('setSize', { size: Number(size) || 20 });
    },
    setLoaderValue({ commit, getters }, value) {
      commit('setLoaderValue', { type: getters.type, value });
    },
  },
  mutations: {
    setCountAdv(state, count) {
      Vue.set(state, 'advCount', count);
    },
    delete(state, { id, reason } = {}) {
      Vue.set(state, state.type, state[state.type].filter((_id) => id !== _id));
      if (reason && state.archive && state.archive.length) {
        Vue.set(state, 'archive', state.archive.unshift(id));
      }
      state.advertisement.info[id].status = 'ARCHIVED';
    },
    setAdvertisements(state, { payload, type } = {}) {
      Vue.set(state, type, payload.map(Number));
    },
    setVinDataByUserId(state, { data }) {
      Vue.set(state, 'vinDataByUserId', data);
    },
    exchangeRates(state, { exchangeRates }) {
      Vue.set(state, 'exchangeRates', exchangeRates);
    },
    setPage(state, { page } = {}) {
      Vue.set(state, 'page', page);
    },
    setSize(state, { size } = {}) {
      Vue.set(state, 'size', size);
    },
    setType(state, { type } = {}) {
      Vue.set(state, 'type', type);
    },
    setLoaderValue(state, { type, value }) {
      Vue.set(state.showLoader, type, value);
    },
  },
  modules: {
    filter,
    advertisement,
    popups,
    manage,
  },
};
