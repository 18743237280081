import Vue from 'vue';
import _laxios from '../../_laxios';

const {cabinet: {content} = {}} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		data: {}
	}, content),
	actions: {
		content({commit, rootGetters, getters}, Cookie) {
			let {
				'cabinet/userInfo/_auth': _auth,
				'lang/id': langId,
				'shared/device/isMobile': isMobile,
			} = rootGetters;
			let data = {
				headers: {}
			};
			let sendRequest = false;
			if (Cookie) {
				data.headers.Cookie = Cookie;
			}
			data.params = {
				langId: langId,
				isMobileWeb: isMobile
			};

			if (_TARGET_ === 'client') {
				if (!Object.keys(getters.statistic).length) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.content
					.request(data)
					.then(payload => commit('content', {payload}))
					.catch(e => {
                      console.error('/cabinet/api/main_page/content/', e.message);
					});
			}
		}
	},
	mutations: {
		content(state, {payload}) {
			Vue.set(state, 'data', payload);
		}
	},
	getters: {
		content({data}) {
			return data;
		},
		statistic({data}) {
			return data.statistic || {};
		},
		commercialServices({data}) {
			return data.commercialServices || {};
		},
		manageCalls({data}) {
			return data.manageCalls || {};
		},
		ordersCustomers({data}) {
			return data.ordersCustomers || {};
		},
		publicationDiscounts({data}) {
			return data.publicationDiscounts || {};
		},
		userBudget({data}) {
			return data.userBudget || {};
		},
		investmentsAndProfit({data}) {
			return data.investmentsAndProfit || [];
		}
	}
};
