import Vue from 'vue';
import _laxios from '../../_laxios';

const {cabinet: {forBusiness} = {}} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		data: {}
	}, forBusiness),
	actions: {
		forBusiness({commit, rootGetters, getters}, Cookie) {
			let {'lang/id': langId} = rootGetters;
			let {'cabinet/userInfo/_auth': _auth} = rootGetters;
			let data = {
				params: {}
			};
			let sendRequest = false;
			data.params = {
				langId: langId,
				project_id: 1
			};

			if (_TARGET_ === 'client') {
				if (!Object.keys(getters.forBusiness).length) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.forBusiness
					.request(data)
					.then(payload => {
						commit('forBusiness', {payload});
					})
					.catch(e => {
                      console.error('/b2bCooperationList/', e.message);
					});
			}

		}
	},
	mutations: {
		forBusiness(state, {payload}) {
			for (let key in payload) {
				if (payload.hasOwnProperty(key)) {
					Vue.set(state.data, key, payload[key]);
				}
			}
		}
	},
	getters: {
		forBusiness({data}) {
			return data;
		}
	}
};
