module.exports = `
query get($ids: [ID], $langId: ID) {
  get(ids: $ids, langId: $langId) {
    id
    title
    uri
    year
    brand {
      name
    }
    model {
      name
    }
    photos {
      main {
        id
        url
      }
    }
    price {
      main {
        value
        currency {
          sign
        }
      }
      all {
        USD {
          value
        }
        UAH {
          value
        }
      }
    }
    location {
      city {
        name
      }
    }
    owner {
      id
      name
      avatar
      rating {
        average
        count
      }
      phones {
        formatted
      }
    }
  }
}`;