import Vue from 'vue';
import _laxios from '../../_laxios';
import {SET_AUTO_DEALER_PROLONGATION, UPDATE_AUTO_DEALER_PROLONGATION, SET_PROLONGATION, UPDATE_PROLONGATION} from "./mutation/mutation-types";

const {cabinet: {billing} = {}} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		data: {},
		autoDealerProlongation: null,
		prolongation: null,
	}, billing),
	actions: {
		unpaidOrders({ commit, rootGetters, getters }, Cookie) {
			let {'cabinet/userInfo/_auth': _auth} = rootGetters;
			let data = {
				headers: {}
			};
			let sendRequest = false;
			if (Cookie) {
				data.headers.Cookie = Cookie;
			}

			if (_TARGET_ === 'client') {
				if (!Object.keys(getters.unpaidOrders).length) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.unpaidOrders
					.request(data)
					.then(payload => {
						commit('unpaidOrders', {payload});
					})
					.catch(e => {
                        console.error('/billing/unpaidOrders/', e.message);
					});
			}
		},
		async getAutoDealerProlongationData ({ commit }, Cookie ) {
			let data = {}
			if (Cookie) {
				data.headers = { Cookie };
			}
			 try {
				 const response = await _laxios.getAutoDealerProlongation.request(data)
				 commit(SET_AUTO_DEALER_PROLONGATION, response)
			 }
			catch (e) {
				console.error('Помилка отримання данних для пролонгації пакету')
			}
		},
		async postAutoDealerProlongation ({ getters }) {
			const { autoDealerProlongation: data } = getters;
			try {
				return await _laxios.postAutoDealerProlongation.request({data})
			}
			catch (e) {
				console.error('Помилка отримання данних для пролонгації пакету')
			}
		},
		updateAutoDealerProlongationData ({ commit }, payload ) {
			commit(UPDATE_AUTO_DEALER_PROLONGATION, payload)
		},
		async getProlongationData ({ commit }, Cookie ) {
			let data = {}
			if (Cookie) {
				data.headers = { Cookie };
			}
			try {
				const response = await _laxios.getProlongation.request(data)
				commit(SET_PROLONGATION, response)
			}
			catch (error) {
				console.error('Помилка отримання данних для пролонгації пакету')
				return null;
			}
		},
		async postProlongation ({ getters }) {
			const { prolongation: data } = getters;
			try {
				return await _laxios.postProlongation.request({data})
			}
			catch (error) {
				console.error('Помилка отримання данних для пролонгації пакету')
				return null;
			}
		},
		updateProlongationData ({ commit }, payload ) {
			commit(UPDATE_PROLONGATION, payload)
		},
	},
	mutations: {
		unpaidOrders(state, { payload }) {
			for (let key in payload) {
				if (payload.hasOwnProperty(key)) {
					Vue.set(state.data, key, payload[key]);
				}
			}
		},
		[SET_AUTO_DEALER_PROLONGATION] (state, data) {
			state.autoDealerProlongation = data
		},
		[UPDATE_AUTO_DEALER_PROLONGATION] (state, data) {
			state.autoDealerProlongation = {...state.autoDealerProlongation, ...data}
		},
		[SET_PROLONGATION] (state, data) {
			state.prolongation = data
		},
		[UPDATE_PROLONGATION] (state, data) {
			state.prolongation = {...state.prolongation, ...data}
		},
	},
	getters: {
		unpaidOrders({ data }) {
			return data;
		},
		autoDealerProlongation: (state) => state.autoDealerProlongation,
		prolongation: (state) => state.prolongation,
	}
};
