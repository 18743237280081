import Vue from 'vue';
import _laxios from '../../_laxios';

const {cabinet: {rating} = {}} = __INITIAL_STATE__;
module.exports = {
	namespaced: true,
	state: Object.assign({
		data: {},
		empty_reason_ids: []
	}, rating),
	actions: {
		rating({commit, rootGetters, getters}, Cookie) {
			let {'cabinet/userInfo/_auth': _auth} = rootGetters;
			let {'lang/id': langId} = rootGetters;
			let data = {
				headers: {}
			};
			let sendRequest = false;

			if (Cookie) {
				data.headers.Cookie = Cookie;
			}
			data.params = {
				langId: langId
			};

			if (_TARGET_ === 'client') {
				if (!Object.keys(getters.rating).length) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.rating
					.request(data)
					.then(payload => {
						commit('rating', payload);
					})
					.catch(e => {
                      console.error('/users/karma/', e.message);
					});
			}
		},
		ratingState({commit, rootGetters, getters}, Cookie) {
			let {'cabinet/userInfo/_auth': _auth} = rootGetters;
			let data = {
				headers: {}
			};
			let sendRequest = false;

			if (Cookie) {
				data.headers.Cookie = Cookie;
			}

			if (_TARGET_ === 'client') {
				if (!Object.keys(getters.ratingState).length) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.ratingState
					.request(data)
					.then(payload => {
						commit('ratingState', payload);
					})
					.catch(e => {
                      console.error('/users/karma/state/', e.message);
					});
			}

		}
	},
	mutations: {
		rating(state, payload) {
			Vue.set(state, 'data', payload);
		},
		ratingState(state, {empty_reason_ids}) {
			Vue.set(state, 'empty_reason_ids', empty_reason_ids);
		}
	},
	getters: {
		rating({data}) {
			return data;
		},
		ratingState({empty_reason_ids}) {
			return empty_reason_ids;
		}
	}
};
