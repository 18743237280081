import Vue from 'vue';
import _laxios from "../../../_laxios";

module.exports = {
	namespaced: true,
	state: {
		series: [{}],
		currentCategory: 0,
		currentBrand: 0,
		currentModel: 0,
	},
	getters: {
		category(state) {
			return state.category === undefined ? 0 : state.category;
		},
		series(state) {
			return state.series === undefined ? [{}] : state.series;
		},
		price(state) {
			return state.price === undefined ? {gte: undefined, lte: undefined} : state.price;
		},
		currency({currency}) {
			return currency === undefined ? 1 : currency;
		},
		top(state) {
			return state.top === undefined ? 0 : state.top;
		},
		new(state) {
			return state.new === undefined ? false : state.new;
		},
		publication(state) {
			return state.publication === undefined ? {} : state.publication;
		},
		expire({expire}) {
			return expire === undefined ? {} : expire;
		},
		sort(state) {
			return state.sort === undefined ? 'levels' : state.sort;
		},
		query(state) {
			let req = {};

			if (state.hasOwnProperty('category')) {
				req.category = state.category;
			}

			for (let s of state.series) {
				let localObj = {};

				if (s.brand) {
					localObj.brandId = s.brand;
					if (s.model) {
						localObj.modelId = s.model;
					}

					req.nested = req.nested || [];
					req.nested.push(localObj);
				}
			}

			if (state.hasOwnProperty('price')) {
				req.price = {};
				for (let key of Object.keys(state.price)) {
					req.price[key] = state.price[key];
				}
			}
			if (state.hasOwnProperty('top')) {
				if (state.top === 'withTop') {
					req.level = 1;
				}
				if (state.top === 'withoutTop') {
					req.level = 0;
				}
			}

			if (state.hasOwnProperty('currency')) {
			 req.currency = state.currency;
			}

			if (state.hasOwnProperty('publication')) {
				req.publication = {};
				if (state.publication.hasOwnProperty('gte')) {
					req.publication.gte = state.publication.gte;
				}
				if (state.publication.hasOwnProperty('lte')) {
					req.publication.lte = state.publication.lte;
				}
			}
			if (state.hasOwnProperty('expire')) {
				req.expire = {};
				if (state.expire.hasOwnProperty('gte')) {
					req.expire.gte = state.expire.gte;
				}
				if (state.expire.hasOwnProperty('lte')) {
					req.expire.lte = state.expire.lte;
				}
			}
			return req;
		},
		filterData(state) {
			return state.filterData === undefined ? {} : state.filterData;
		},
		getCategories(state) {
			return state.categories === undefined ? [] : state.categories;
		},
		getBrands(state) {
			return state.brands === undefined ? [] : state.brands;
		},
		getCurrentCategory(state) {
			return state.currentCategory === undefined ? 0 : state.currentCategory;
		},
	},
	actions: {
		reset({commit}) {
			commit('reset');
		},
		currency({commit, getters}, value){
			value = Number(value);
			if (value) {
				commit('setValue', {name: 'currency', value})
			} else {
				commit('setValue', {name: 'currency'})
			}
		},
		category({commit, getters}, value) {
			value = Number(value) || 0;
			if (value) {
				commit('setValue', {name: 'category', value});
			} else {
				commit('setValue', {name: 'category'});
			}
			for (let index = 0; index < getters.series.length; index++) {
				commit('setBrand', {index});
			}
		},
		brand({commit, getters}, {value, index}) {
			value = Number(value) || 0;
			if (value) {
				commit('setBrand', {index, value});
			} else {
				commit('setBrand', {index});
			}
			commit('setModel', {index});
		},
		model({commit}, {value, index}) {
			value = Number(value) || 0;
			if (value) {
				commit('setModel', {index, value});
			} else {
				commit('setModel', {index});
			}
		},
		price({commit}, value) {
			value = value || {};
			if (Object.keys(value).length) {
				commit('setValue', {name: 'price', value});
			} else {
				commit('setValue', {name: 'price'});
			}
		},
		top({commit}, value) {
			value = value || false;
			if (value) {
				commit('setValue', {name: 'top', value});
			} else {
				commit('setValue', {name: 'top'});
			}
		},
		new({commit}, value) {
			value = Boolean(value) || false;
			if (value) {
				commit('setValue', {name: 'new', value});
			} else {
				commit('setValue', {name: 'new'});
			}
		},
		publication({commit}, value) {
			value = value || {};
			if (Object.keys(value).length) {
				let gte = value.gte;
				let lte = value.lte;
				if (gte && lte && new Date(gte) > new Date(lte)) {
					[value.gte, value.lte] = [lte, gte];
				}
				commit('setValue', {name: 'publication', value});
			} else {
				commit('setValue', {name: 'publication'});
			}
		},
		expire({commit}, value) {
			value = value || {};
			if (Object.keys(value).length) {
				let gte = value.gte;
				let lte = value.lte;
				if (gte && lte && new Date(gte) > new Date(lte)) {
					[value.gte, value.lte] = [lte, gte];
				}
				commit('setValue', {name: 'expire', value});
			} else {
				commit('setValue', {name: 'expire'});
			}
		},
		sort({commit}, value) {
			value = value || {};
			if (Object.keys(value).length) {
				commit('setValue', {name: 'sort', value});
			} else {
				commit('setValue', {name: 'sort'});
			}
		},
		fetchFilterParam({commit}, {currentPage, langId}) {
			return _laxios
				.fetchFilterParam
				.expandUrl({type: currentPage, langId: langId})
				.request({responseType: 'json'})
				.then((response) => {
					commit('setFilterParam', response);
					let categories = [];
					for (let categoryId in response) {
						let category = {
							value: categoryId,
							name: response[categoryId].name
						}
						categories.push(category);
					}
					commit('setCategories', categories);
				})
                .catch((e)=> {
                    console.error('fetchFilterParam =>', e)
                });
		},
		filterBrandByCategory({commit, getters}, categoryId) {
			if (categoryId !== undefined && categoryId != 0) {
				commit('setCurrentCategory', categoryId);
				const brandsData = getters.filterData[categoryId].brands;
				let brands = [];
				for (let brandId in brandsData) {
					let brand = {
						value: brandId,
						name: brandsData[brandId].name
					};
					brands.push(brand);
				}
				commit('setBrands', brands);
			} else {
				commit('setBrands', []);
				commit('setModels', []);
				commit('setCurrentBrand', 0);
				commit('setCurrentModel', 0);
				commit('setCurrentCategory', 0);
			}
		},
		filterModelByBrand({commit, getters}, brandId) {
			if (brandId !== undefined && brandId != 0) {
				commit('setCurrentBrand', brandId);
				const currentCategory = getters.getCurrentCategory;
				const brandsData = getters.filterData[currentCategory].brands;
				const modelData = brandsData[brandId].models;
				let models = [];
				for (let modelId in modelData) {
					let model = {
						value: modelId,
						name: modelData[modelId]
					};
					models.push(model);
				}
				commit('setModels', models);
				return models;
			} else {
				commit('setModels', []);
				commit('setCurrentModel', 0);
				return [];
			}
		},
	},
	mutations: {
		reset(state) {
			for (let key of Object.keys(state)) {
				if (['categories', 'filterData'].indexOf(key) === -1) {
					Vue.delete(state, key);
				}
			}
			Vue.set(state, 'series', [{}]);
		},
		addSeriesRow(state) {
			state.series.push({});
		},
		removeSeriesRow(state, index) {
			state.series.splice(index, 1);
		},
		setValue: (state, payload) => {
			if (!payload.hasOwnProperty('value')) {
				Vue.delete(state, payload.name);
			} else {
				Vue.set(state, payload.name, payload.value);
			}
		},
		setBrand: (state, payload) => {
			if (!payload.hasOwnProperty('value')) {
				Vue.set(state.series, payload.index, {});
			} else {
				Vue.set(state.series, payload.index, {brand: payload.value});
			}
		},
		setModel: (state, payload) => {
			let s = state.series[payload.index];
			if (!payload.hasOwnProperty('value')) {
				delete s.model;
			} else {
				s.model = payload.value;
			}
			Vue.set(state.series, payload.index, s);
		},
		setFilterParam: (state, payload) => {
			Vue.set(state, 'filterData', payload);
		},
		setCategories: (state, categories) => {
			Vue.set(state, 'categories', categories);
		},
		setBrands: (state, brands) => {
			Vue.set(state, 'brands', brands);
		},
		setModels: (state, models) => {
			Vue.set(state, 'models', models);
		},
		setCurrentCategory: (state, categoryId) => {
			Vue.set(state, 'currentCategory', categoryId);
		},
		setCurrentModel: (state, modelId) => {
			Vue.set(state, 'currentModel', modelId);
		},
		setCurrentBrand: (state, brandId) => {
			Vue.set(state, 'currentBrand', brandId);
		}
	}
};
