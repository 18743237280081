module.exports = `
query advertisements($ids: [ID], $langId: ID) {
  advertisements(ids: $ids, langId: $langId) {
    id
    createdAt
    uri
    title
    publication {
      expiredAt
    }
    category {
      id
    }
    brand {
    	id
      eng
    }
    model {
    	id
      eng
    }
    status
    deleteReason {
    	date
    	reason
    }
    custom
    abroad
    photos {
      main {
        id
      }
		}
		prices {
      basePrice
      levelPrice
      publications
      currentPrice
      reasonId
      freePublicationOption
    }
    levels {
      active {
        value
        createdAt
        expiredAt
      }
      queue {
        value
        createdAt
        expiredAt
      }
    }
  }
}
`;
