import Vue from 'vue';
import _laxios from '../../_laxios';

const {cabinet: {blocks} = {}} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		data: {}
	}, blocks),
	actions: {
		blocks({commit, rootGetters, getters}, Cookie) {
			let {
				'cabinet/userInfo/_auth': _auth,
				'shared/device/isMobile': isMobile,
			} = rootGetters;
			let {'lang/id': langId} = rootGetters;
			let data = {
				headers: {},
				params: {
					isMobileWeb: isMobile
				}
			};
			let sendRequest = false;

			if (Cookie) {
				data.headers.Cookie = Cookie;
			}

			if (_TARGET_ === 'client') {
				if (!Object.keys(getters.blocks).length) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}
			if (sendRequest) {
				return _laxios.blocks
					.request(data)
					.then(payload => {
						commit('blocks', {payload});
					})
					.catch(e => {
                        console.error('/cabinet/api/main_page/blocks/', e.message);
					});
			}
		}
	},
	mutations: {
		blocks(state, {payload}) {
			for (let key in payload) {
				if (payload.hasOwnProperty(key)) {
					Vue.set(state.data, key, payload[key]);
				}
			}
		}
	},
	getters: {
		blocks({data}) {
			return data;
		}
	}
};
