import Vue from 'vue';
import _laxios from '../../_laxios';

module.exports = {
  namespaced: true,
  state: {
    calculator: {},
  },
  actions: {
    calculator({ commit, rootGetters }) {
      const { 'lang/id': langId } = rootGetters;
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      const data = {
        params: { langId },
      };

      return _laxios.companyCalculator
        .request(data)
        .then((payload) => {
          commit('calculator', { payload });
        })
        .catch((e) => {
          console.error('/cabinet/api/company/statistic/calculator/', e.message);
        });
    },
    efficiency({ commit, rootGetters }, { from = '', to = '' }) {
      const { 'lang/id': langId } = rootGetters;
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      const data = {
        params: {
          langId,
          from,
          to,
        },
      };

      return _laxios.companyEfficiency
        .request(data)
        .then((data) => data)
        .catch((e) => {
          console.error('/cabinet/api/company/statistic/companyEfficiency/', e.message);
          return {};
        });
    },
    companyById({ commit, rootGetters }, { id, withoutCache }) {
      const { 'lang/id': langId } = rootGetters;
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      return _laxios.company
        .expandUrl({ id })
        .request({ params: { langId, withoutCache } })
        .then((companyInfo = {}) => companyInfo)
        .catch((e) => {
          console.error('/company_api/api/company/', e.message);
        });
    },
    updateCompanyInfo({ commit, rootGetters }, { data = {}, id = 0 }) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      return _laxios.updateCompanyInfo
        .expandUrl({ id })
        .request({ data })
        .then((res) => res)
        .catch((e) => {
          console.error('/company_api/api/companyUpdate/', e.message);
        });
    },
    setCompanyLogo({ commit, rootGetters }, data) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      return _laxios.setCompanyLogo
        .request({ data })
        .then((url) => url)
        .catch((e) => {
          console.error('/company_api/setLogo/', e.message);
        });
    },
  },
  mutations: {
    calculator(state, { payload }) {
      Vue.set(state, 'calculator', payload);
    },
  },
  getters: {
    calculator({ calculator }) {
      return calculator;
    },
  },
};
