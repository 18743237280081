import Vue from 'vue';
import _laxios from '../../_laxios';

const {cabinet: {publicationsStatistic} = {}} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		data: {},
	}, publicationsStatistic),
	actions: {
		publicationsStatistic({commit, rootGetters, getters}, ctx) {
			let {'cabinet/userInfo/_auth': _auth} = rootGetters;
			let data = {
				headers: {},
				params: {}
			};
			let sendRequest = false;

			if (ctx.cookies) {
				data.headers.Cookie = data.cookies;
			}
			if (ctx.params) {
				data.params = ctx.params;
			}

			if (_TARGET_ === 'client') {
				if (!Object.keys(getters.publicationsStatistic).length) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.publication_statistic
					.request(data)
					.then(payload => {
						commit('publicationsStatistic', {payload});
					})
					.catch(e => {
                      console.error('/cabinet/api/publications/', e.message);
					});
			}
		}
	},
	mutations: {
		publicationsStatistic(state, {payload}) {
			Vue.set(state, 'data', payload);
		}
	},
	getters: {
		publicationsStatistic({data}) {
			return data;
		}
	}
};
