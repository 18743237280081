import Vue from 'vue';
import _laxios from '../../_laxios';

module.exports = {
	namespaced: true,
	state: {
		data: {},
		autoRate: {}
	},
	actions: {
		auction({commit, rootGetters}, params) {
			let {'lang/id': langId} = rootGetters;
			let {'cabinet/userInfo/_auth': _auth} = rootGetters;
			let data = {
				headers: {}
			};

			data.params = Object.assign({
				langId: langId
			}, params);

			return _laxios.auctionPerformance
				.request(data)
				.then(payload => {
					commit('auction', {payload});
				})
				.catch(e => {
                  console.error('/api/call-tracking/public/auction/performance/', e.message);
				});
		},
		autoRate({commit, rootGetters}, {advertisementId, params}) {
			let {'lang/id': langId} = rootGetters;
			let {'cabinet/userInfo/_auth': _auth} = rootGetters;
			let data = {
				params: Object.assign({
					langId: langId
				}, params)
			};

			return _laxios.auctionAutoRate
				.expandUrl({advertisementId})
				.request(data)
				.then(payload => {
					commit('autoRate', {payload});
				})
				.catch(e => {
                  console.error('/api/call-tracking/public/auction/auto-rate-info/{advertisementId}/', e.message);
				});

		}
	},
	mutations: {
		auction(state, {payload}) {
			Vue.set(state, 'data', payload);
		},
		autoRate(state, {payload}) {
			Vue.set(state, 'autoRate', payload);
		}
	},
	getters: {
		auction({data}) {
			return data;
		},
		autoRate({autoRate}) {
			return autoRate;
		}
	}
};
