module.exports = `
query get($ids: [ID], $langId: ID) {
  get(ids: $ids, langId: $langId) {
    id
    brand {
      id
      eng
      name
    }
    model {
      id
      eng
      name
    }
    category {
      id
    }
    year
    status
    title
    uri
    photos {
      main {
        id
      }
      all (limit: 201) {
        id
      }
    }
    price {
      main {
        value
        currency {
          sign
        }
      }
      all {
        USD {
          value
        }
        UAH {
          value
        }
      }
    }
    statistic {
      clicks
      views
      notepadCount
    }
    autotest {
      lawyerPackage {
        orderId
        addDate
      }
      technicalPackage {
        orderId
        addDate
      }
      vinPackage {
        orderId
        addDate
        freeCheck
        vinCode
      }
    }
    aar {
      disable
    }
    publication {
      createdAt
      expiredAt
    }
    levels {
      active {
        value
        createdAt
        expiredAt
      }
    }
    location {
      city {
        name
      }
      state {
        id
      }
    }
    gearbox {
      name
    }
    race
    engine {
      volume {
        liters
      }
    }
    VIN
    fuel {
      id
      name
    }
    createdAt
    deleteReason {
      date
      message
      reason
      moderationDeleteType
      shortMessage 
    }
    custom
    abroad
    moderatedParams {
      moderatedAbroad
  	}
    autoBuy {
      status
      meetDate
    }
    postmoderation {
  	  color
      title
      description
      buttonShow
      buttonText
      errorText
      additionalText
      additionalLink
      dateEnd
      timerText
  	}
    autotest { 
      plateNumberData {
        value
      }
    }
  }
}
`;
