module.exports = `
query get($ids: [ID]) {
  get(ids: $ids) {
    brand {
      id
      name
    }
    model {
      id
      name
    }
    category {
      id
      name
    }
  }
}
`;
