import Vue from 'vue';
import _laxios from '../../../_laxios';

module.exports = {
  namespaced: true,
  state: {
    advertisements: {
      active: [],
      archive: [],
      draft: [],
      awaitForPayment: [],
    },
    additionalInfo: {
      active: {},
      archive: {},
      draft: {},
      awaitForPayment: {},
    },
    reload: false,
    virtualFreePublication: {},
    republicationInfo: {
      general: {},
      ids: {},
    },
    getAdvertisementAssignedPhones: {},
    callTrackingByUser: {},
    callTrackingDefaultInfo: {},
  },
  getters: {
    reload({ reload }) {
      return reload || false;
    },
    advertisements({ advertisements }) {
      return advertisements || [];
    },
    additionalInfo({ additionalInfo }) {
      return additionalInfo || [];
    },
    getRepublicationInfoById({ republicationInfo: { ids } }) {
      return function (id) {
        return ids[id] || {};
      };
    },
    getGeneralRepublicationInfo({ republicationInfo: { general } }) {
      return general;
    },
    getAdvertisementAssignedPhones({ getAdvertisementAssignedPhones }) {
      return getAdvertisementAssignedPhones;
    },
    callTrackingByUser({ callTrackingByUser }) {
      return callTrackingByUser;
    },
    callTrackingDefaultInfo({ callTrackingDefaultInfo }) {
      return callTrackingDefaultInfo;
    },
  },
  actions: {
    reload({ commit }, flag = true) {
      commit('reload', flag);
    },
    forecastPosition({ commit, rootGetters }, {
      top, autoId, categoryId, brandId, modelId, custom, abroad,
    }) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      const params = {
        category_id: categoryId,
        marka_id: brandId,
        model_id: modelId,
        custom: Number(custom) + 1,
        abroad: Number(abroad),
        searchType: abroad ? 3 : 1,
      };
      return _laxios.forecastPosition
        .expandUrl({ autoId, top })
        .request({ params })
        .catch((e) => {
          console.error('GraphQL, ERROR:_laxios.forecastPosition, vue/store/cabinet/advertisements/manage/index.js:', e.message);
        });
    },
    getCurrentPosition({ commit, rootGetters }, {
      autoId, categoryId, brandId, modelId, custom, abroad,
    }) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      const params = {
        category_id: categoryId,
        marka_id: brandId,
        model_id: modelId,
        custom: Number(custom) + 1,
        abroad: Number(abroad),
        searchType: abroad ? 3 : 1,
      };
      return _laxios.currentPosition
        .expandUrl({ autoId })
        .request({ params })
        .catch((e) => {
          console.error('/levels/position/{autoId}/', e.message);
        });
    },
    advertisements({ commit, rootGetters }, { type }) {
      const { 'shared/url/current': url, 'cabinet/userInfo/_auth': _auth } = rootGetters;

      const data = {
        query: require('../../../_graphql/queries/advertisements'),
        variables: {
          params: [
            {
              userId: _auth.userId,
            },
          ],
          order: { field: type === 'archive' ? undefined : 'levels' },
          searchType: type,
          limit: 2000,
        },
      };

      return _laxios
        .graphql
        .request({ data })
        .then(({ data: { searchCommon: advertisements = [] } = {} } = {}) => {
          const ids = [];
          for (const advertisement of advertisements) {
            if (advertisement) {
              ids.push(Number(advertisement.id));
            }
          }
          commit('advertisements', { ids, type });
        })
        .catch((e) => {
          console.error('GraphQL, ERROR:advertisements, vue/store/cabinet/advertisements/manage/index.js:', e.message);
        });
    },
    additionalInfo({ commit, rootGetters, getters }, { ids, type }) {
      const { 'shared/url/current': url } = rootGetters;
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      const getterArray = Object.keys(getters.additionalInfo[type]);
      // для того, щоб не тягнути інфо про машинки, інфо про які вже було витягнуто
      const relevantIds = ids.map((x) => x.toString()).filter((x) => getterArray.indexOf(x) === -1);
      const data = {
        query: require('../../../_graphql/queries/adsManage'),
        variables: {
          ids: relevantIds,
        },
      };

      return relevantIds.length ? _laxios
        .graphql
        .request({ data })
        .then(({ data: { advertisements = [] } = {} } = {}) => {
          const additionalObj = {};
          if (advertisements.length) {
            advertisements.forEach((item) => {
              additionalObj[item.id] = item;
            });
          }
          commit('additionalInfo', { info: additionalObj, type });
        })
        .catch((e) => {
          console.error('GraphQL, ERROR:::additionalInfo, vue/store/cabinet/advertisements/manage/index.js:', e.message);
        }) : {};
    },
    sendData({ commit }, formData) {
      return _laxios
        .userLevels
        .request({ data: formData })
        .then((response) => response);
    },
    sendDataRestore({ commit }, formDataRestore) {
      return _laxios
        .userRestore
        .request({ data: formDataRestore })
        .then((response) => response);
    },
    fetchDefaultLevelInfo() {
      return _laxios
        .fetchDefaultLevelInfo
        .request()
        .then((response) => response)
        .catch((e) => {
          console.error('ERROR:::fetchDefaultLevelInfo', e.message);
          return { defaultPeriod: 7, defaultLevel: 7 };
        });
    },
    getRepublicationInfo({ commit, rootGetters, getters }, { id = 0, adsCount = 0 } = {}) {
      const { 'lang/id': langId } = rootGetters;
      const params = {
        langId,
      };
      if (Number(id)) {
        Object.assign(params, { autoId: id });
      }
      if (Number(adsCount)) {
        Object.assign(params, { adsCount });
      }
      return _laxios
        .getRepublicationInfo
        .request({ params })
        .then((response = {}) => {
          if (Number(response.advSettings)) {
            commit('addRepublicationInfoById', { id, info: response });
          } else {
            commit('setGeneralRepublicationInfo', response);
          }
          return response;
        })
        .catch((e) => console.error('ERROR:::getRepublicationInfo', e.message));
    },
    updateRepublicationInfo({ commit, rootGetters, getters }, data) {
      const { 'cabinet/advertisements/advertisementsActive': activeAds } = rootGetters;
      const { autoIds = [] } = data;
      return _laxios
        .updateRepublicationInfo
        .request({ data })
        .then((response) => {
          if (autoIds.length) {
            autoIds.map((id) => commit('addRepublicationInfoById', { id, info: data }));
          } else {
            commit('setGeneralRepublicationInfo', data);
            activeAds.map((id) => commit('addRepublicationInfoById', { id, info: data }));
          }
          return response;
        })
        .catch((e) => console.error('ERROR:::updateRepublicationInfo', e.message));
    },
    getAdvertisementAssignedPhones({ commit, rootGetters }, { advertisementId }) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      return _laxios.getAdvertisementAssignedPhones
        .expandUrl({ advertisementId })
        .request()
        .then((response = {}) => {
          commit('getAdvertisementAssignedPhones', response);
          return response;
        })
        .catch((e) => {
          console.error('/cabinet/api/car/{advertisementId}/assigned-phones/', e.message);
        });
    },
    postAdvertisementAssignedPhones({ rootGetters }, { advertisementId, requestData }) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      return _laxios.postAdvertisementAssignedPhones
        .expandUrl({ advertisementId })
        .request({ data: requestData })
        .then()
        .catch((e) => {
          console.error('/cabinet/api/car/{advertisementId}/assigned-phones/', e.message);
        });
    },
    uploadAdvertisementDocs(_, { files, autoId }) {
      const formData = new FormData();

      formData.append('autoId', autoId);
      Object.values(files).forEach((file, index) => {
        formData.append(`file${index + 1}`, file);
      });

      return _laxios.uploadAdvertisementDocs
        .request({ data: formData })
        .catch((e) => {
          console.error('/cabinet/api/directories/declarations/files/', e.message);
          return e.response;
        });
    },
    uploadPostModerationDocs(_, { files, autoId }) {
      const formData = new FormData();
      formData.append('autoId', autoId);

      Object.values(files).forEach((file, index) => {
        formData.append(`file${index + 1}`, file);
      });

      return _laxios.uploadPostModerationDocs
        .request({ data: formData })
        .catch((e) => {
          console.error('/cabinet/api/directories/documents/files/', e.message);
          return e.response;
        });
    },
    getAdvertisementDocs({ rootGetters }, { autoId }) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;

      return _laxios.getAdvertisementDocs
        .request({ params: { autoId } })
        .catch((e) => {
          console.error('/cabinet/api/declarations/', e.message);
        });
    },
    // Advertisement call tracking (switcher)
    getCallTrackingByAuto({}, { autoId, data }) {
      return _laxios.getCallTrackingByAuto
        .expandUrl({ autoId })
        .request({ data })
        .catch((e) => {
          console.error('getCallTrackingByAuto', e.message);
        });
    },
    getCallTrackingByUser({ commit }) {
      return _laxios.getCallTrackingByUser
        .request()
        .then((response = {}) => {
          commit('callTrackingByUser', response.length && response[0]);
          return response[0];
        })
        .catch((e) => {
          console.error('getCallTrackingByUser', e.message);
        });
    },
    fetchCallTrackingInfo({ commit }) {
      return _laxios.fetchCallTrackingInfo
        .request()
        .then((response = {}) => {
          commit('callTrackingDefaultInfo', response);
          return response;
        })
        .catch((e) => {
          console.error('fetchCallTrackingInfo', e.message);
        });
    },
    switchCallTracking({}, { autoId, data }) {
      return _laxios.switchCallTracking
        .expandUrl({ autoId })
        .request({ data })
        .catch((e) => {
          console.error('switchCallTracking', e.message);
        });
    },
  },
  mutations: {
    reload(state, flag) {
      Vue.set(state, 'reload', flag);
    },
    advertisements(state, { ids, type }) {
      Vue.set(state.advertisements, type, ids);
    },
    additionalInfo(state, { info, type }) {
      Object.assign(info, state.additionalInfo[type]);
      Vue.set(state.additionalInfo, type, info);
    },
    setGeneralRepublicationInfo(state, info = {}) {
      Vue.set(state.republicationInfo, 'general', {
        ...state.republicationInfo.general,
        ...info,
      });
    },
    addRepublicationInfoById(state, { id = 0, info = {} }) {
      delete info.autoIds;
      Vue.set(state.republicationInfo.ids, id, {
        ...state.republicationInfo.ids[id],
        ...info,
      });
    },
    getAdvertisementAssignedPhones(state, payload) {
      Vue.set(state, 'getAdvertisementAssignedPhones', payload);
    },
    callTrackingByUser(state, payload) {
      Vue.set(state, 'callTrackingByUser', payload);
    },
    callTrackingDefaultInfo(state, payload) {
      Vue.set(state, 'callTrackingDefaultInfo', payload);
    },
  },
};
