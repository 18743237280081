import Vue from 'vue';
import _laxios from '../../_laxios';

const { cabinet: { notifications } = {} } = __INITIAL_STATE__;

const categoryIdToFieldNameMap = {
  1: 'promo',
  2: 'sell',
  3: 'buy',
  4: 'favorites',
  5: 'info',
};

const notificationCategories = ['promoNew', 'sellNew', 'buyNew', 'favoritesNew', 'infoNew'];

module.exports = {
  namespaced: true,
  state: {
    data: {},
    counters: {},
    reloadMainPage: false,
    ...notifications,
  },
  actions: {
    notifications({ commit, rootGetters, getters }, Cookie) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      const data = {
        headers: {},
      };
      let sendRequest = false;

      if (Cookie) {
        data.headers.Cookie = Cookie;
      }

      if (_TARGET_ === 'client') {
        if (!Object.keys(getters.notifications).length) {
          sendRequest = true;
        }
      } else {
        sendRequest = true;
      }

      if (sendRequest || getters.reloadMainPage) {
        return _laxios.notifications
          .request(data)
          .then((payload) => {
            getters.reloadMainPage && commit('reloadMainPage', false);
            commit('notifications', { payload });
          })
          .catch((e) => {
            console.error('/cabinet/api/notifications/preview?noCache=true/', e.message);
          });
      }
    },
    counters({ commit, rootGetters, getters }, Cookie) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      let sendRequest = false;
      const data = {
        headers: {},
        params: {},
      };

      if (Cookie) {
        data.headers.Cookie = Cookie;
      }

      if (_TARGET_ === 'client') {
        if (!Object.keys(getters.counters).length) {
          sendRequest = true;
        }
      } else {
        sendRequest = true;
      }

      if (sendRequest) {
        return _laxios.notificationsCount
          .request(data)
          .then((payload) => {
            const newData = { ...payload };
            if (newData?.categories) {
              newData.categories.total = 0;
              notificationCategories.forEach((name) => {
                if (newData.categories[name]) {
                  newData.categories.total += Number(newData.categories[name]);
                }
              });
            }
            commit('counters', { payload: newData });
          })
          .catch((e) => {
            console.error('/notifications/api/public/count/', e.message);
          });
      }
    },
    clearNotifications() {
      const data = {
        values: {
          is_viewed: true,
        },
        filter: {
          is_deleted: false,
          is_viewed: false,
        },
      };
      return _laxios.notifications_PATCH.request({ data })
        .catch((error) => {
          console.error('clearNotifications_store error::', error.message);
        });
    },
  },
  mutations: {
    notifications(state, { payload }) {
      Vue.set(state, 'data', payload);
    },
    counters(state, { payload }) {
      Vue.set(state, 'counters', payload);
    },
    /**
     * Методи для динамічного оновлення лічильників на SPA
     */
    decrementEmergencyViewedCnt(state) {
      if (!state.counters.emergencyViewed) Vue.set(state, 'counters.emergencyViewed', 0);
      else Vue.set(state, 'counters.emergencyViewed', state.counters.emergencyViewed--);
    },
    decrementInformingViewedCnt(state) {
      if (!state.counters.informingViewed) Vue.set(state, 'counters.informingViewed', 0);
      else Vue.set(state, 'counters.informingViewed', state.counters.informingViewed--);
    },
    decrementImportantViewedCnt(state) {
      if (!state.counters.importantViewed) Vue.set(state, 'counters.importantViewed', 0);
      else Vue.set(state, 'counters.importantViewed', state.counters.importantViewed--);
    },
    incrementEmergencyViewedCnt(state) {
      if (!state.counters.emergencyViewed) { state.counters.emergencyViewed = 0; }
      Vue.set(state, 'counters.emergencyViewed', state.counters.emergencyViewed++);
    },
    incrementInformingViewedCnt(state) {
      if (!state.counters.informingViewed) { state.counters.informingViewed = 0; }
      Vue.set(state, 'counters.informingViewed', state.counters.informingViewed++);
    },
    incrementImportantViewedCnt(state) {
      if (!state.counters.importantViewed) { state.counters.importantViewed = 0; }
      Vue.set(state, 'counters.importantViewed', state.counters.importantViewed++);
    },
    watchNotification(state, { categoryId }) {
      const nameNew = `${categoryIdToFieldNameMap[categoryId]}New`;
      const nameViewed = `${categoryIdToFieldNameMap[categoryId]}Viewed`;
      const categoriesNew = { ...state.counters.categories };
      if (!categoriesNew[nameViewed]) {
        categoriesNew[nameViewed] = 1;
      } else {
        categoriesNew[nameViewed] += 1;
      }
      categoriesNew[nameNew] -= 1;
      categoriesNew.total -= 1;
      Vue.set(state, 'counters', { ...state.counters, categories: categoriesNew });
    },
    deleteNotification(state, { categoryId, isViewed }) {
      const nameNew = `${categoryIdToFieldNameMap[categoryId]}New`;
      const nameViewed = `${categoryIdToFieldNameMap[categoryId]}Viewed`;
      const categoriesNew = { ...state.counters.categories };
      if (isViewed) {
        categoriesNew[nameViewed] -= 1;
      } else {
        categoriesNew[nameNew] -= 1;
        categoriesNew.total -= 1;
      }
      Vue.set(state, 'counters', { ...state.counters, categories: categoriesNew });
    },
    incrementDeletedCnt(state) {
      if (!state.counters.deleted) { state.counters.deleted = 0; }
      Vue.set(state, 'counters.deleted', state.counters.deleted++);
    },
    /**
     * Використовується коли після якихось активностей на розділі Сповіщень
     * потрібно перемалювати блочок з Сповіщенями на головній ЛК
     * @param state
     * @param flag
     */
    reloadMainPage(state, flag = true) {
      Vue.set(state, 'reloadMainPage', flag);
    },

  },
  getters: {
    notifications({ data }) {
      return data;
    },
    counters({ counters }) {
      return counters || {};
    },
    reloadMainPage({ reloadMainPage }) {
      return reloadMainPage || false;
    },
  },
};
