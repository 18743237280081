import Vue from 'vue';
import advertisements from './advertisements';
import balance from './balance';
import constants from './constants';
import notepad from './notepad';
import notifications from './notifications';
import leftBar from './leftBar';
import subscriptions from './subscriptions';
import subscriptionsPage from './subscriptions/subspage';
import billing from './billing';
import forBusiness from './forBusiness';
import content from './content';
import rating from './rating';
import blocks from './blocks';
import tags from './tags';
import statisticCounters from './statisticCounters';
import alerts from './alerts';
import publicationsStatistic from './publicationsStatistic';
import _laxios from '../_laxios';
import advertisementStat from './advertisementStat';
import auction from './auction';
import company from './company';
import autotest from './autotest';

const EXPIRED_SEGMENTS = ['b2cAutoDealer_exp', 'b2cAutoOrder_exp', 'b2cAutoOrder_calls_exp'];

module.exports = {
  state: {
    segment: undefined,
    canGoBack: true,
    promotions: {
      anyPromotion: false,
    },
    segmentAgro: false,
    promoFreePublication: {},
    expiredAutoDealer: {},
    card: {},
  },
  actions: {
    async loadSegment({ commit, rootGetters }, Cookie) {
      const headers = {};
      if (Cookie) {
        headers.Cookie = Cookie;
      }

      try {
        const req = _laxios.segment;
        const { segment } = await req.request({ headers });
        if (segment) {
          if (EXPIRED_SEGMENTS.includes(segment)) {
            commit('setExpiredAutoDealer', segment);
          }
          commit('segment', { segment });
        }
      } catch (e) {
        const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
        console.error('/cabinet/api/segment', e.message);
      }
    },
    promotions({ commit, rootGetters }, Cookie) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      const headers = {};
      if (Cookie) {
        headers.Cookie = Cookie;
      }
      return _laxios.promotions
        .request({ headers })
        .then((payload) => {
          commit('promotions', payload);
        })
        .catch((e) => {
          console.error('/api/discounts/public/promotions', e.message);
        });
    },
    segmentAgro({ commit, rootGetters }, Cookie) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      const headers = {};
      if (Cookie) {
        headers.Cookie = Cookie;
      }
      return _laxios.segmentAgro
        .request({ headers })
        .then(({ isShow = false }) => {
          commit('segmentAgro', isShow);
        })
        .catch((e) => {
          console.error('/cabinet/api/rotator/agro', e.message);
        });
    },
    getCards({ commit, rootGetters }, Cookie) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      const headers = {};
      if (Cookie) {
        headers.Cookie = Cookie;
      }
      return _laxios.getCards
        .request({ headers })
        .then((cards = {}) => {
          const data = Object.values(cards).reduce((memo, item) => {
            memo[item.id] = item;
            return memo;
          }, {});
          commit('setCard', data);
        })
        .catch((e) => {
          if (e.response.status === 404) {
            commit('setCard', {});
          } else {
            console.error('/cards/api/public/all/', e.message);
          }
        });
    },
    deleteCard(_, { cardId }) {
      return _laxios.deleteCard
        .expandUrl({ cardId })
        .request()
        .catch((e) => console.error('deleteCard', e));
    },
    setMainCard(_, { cardId }) {
      return _laxios.setMainCard
        .expandUrl({ cardId })
        .request()
        .catch((e) => console.error('setMainCard', e));
    },
    getPromoFreePublication({ commit, rootGetters }, Cookie) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      const headers = {};
      if (Cookie) {
        headers.Cookie = Cookie;
      }
      return _laxios.getPromoFreePublication
        .request({ headers })
        .then((promo = {}) => {
          commit('setPromoFreePublication', promo);
        })
        .catch((e) => {
          console.error('/cabinet/api/rotator/promoJan/', e.message);
        });
    },
  },
  mutations: {
    segment(state, { segment, canGoBack }) {
      Object.assign(state, { segment, canGoBack });
    },
    promotions(state, data) {
      Object.assign(state, { promotions: data });
    },
    segmentAgro(state, segmentAgro) {
      Object.assign(state, { segmentAgro });
    },
    setCard(state, cards) {
      Vue.set(state, 'card', cards);
    },
    setPromoFreePublication(state, promoFreePublication) {
      Object.assign(state, { promoFreePublication });
    },
    setExpiredAutoDealer(state, segment) {
      const type = {
        isDealer: segment === 'b2cAutoDealer_exp',
        isOrder: segment === 'b2cAutoOrder_exp',
        isCaller: segment === 'b2cAutoOrder_calls_exp',
      };
      Vue.set(state, 'expiredAutoDealer', {
        status: true,
        segment,
        ...type,
      });
    },
  },
  getters: {
    segment({ segment }) {
      return segment;
    },
    canGoBack({ canGoBack }) {
      return canGoBack;
    },
    promotions: ({ promotions }) => promotions,
    segmentAgro({ segmentAgro }) {
      return segmentAgro;
    },
    cards({ card }) {
      return card;
    },
    getPromoFreePublication({ promoFreePublication }) {
      return promoFreePublication;
    },
    getExpiredAutoDealer({ expiredAutoDealer }) {
      return expiredAutoDealer || expiredAutoDealer;
    },
  },
  namespaced: true,
  modules: {
    advertisements,
    balance,
    constants,
    notepad,
    notifications,
    leftBar,
    subscriptions,
    billing,
    forBusiness,
    content,
    rating,
    blocks,
    statisticCounters,
    tags,
    alerts,
    publicationsStatistic,
    advertisementStat,
    auction,
    company,
    autotest,
    subscriptionsPage,
    // userInfo
  },
};
