import Vue from 'vue';
import _laxios from '../../_laxios';

const {cabinet: {leftBar} = {}} = __INITIAL_STATE__;

function keyValue(obj) {
	let result = {};
	if (obj.count) {
		result[obj.blockName] = {count: obj.count};
	} else if (obj.value) {
		result[obj.blockName] = {value: obj.value};
	}
	return result;
}

module.exports = {
	namespaced: true,
	state: Object.assign({
		data: {},
		counters: {}
	}, leftBar),
	actions: {
		leftBar({commit, rootGetters, getters}, Cookie) {
			let {
				'cabinet/userInfo/_auth': _auth,
				'shared/device/isMobile': isMobile,
			} = rootGetters;
			let data = {
				headers: {},
				params: {
					isMobileWeb: isMobile
				}
			};
			let sendRequest = false;
			if (Cookie) {
				data.headers.Cookie = Cookie;
			}

			if (_TARGET_ === 'client') {
				if (!Object.keys(getters.leftBar).length) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.leftBar
					.request(data)
					.then(payload => {
						commit('leftBar', {payload});
					})
					.catch(e => {
                      console.error('/cabinet/api/left_bar/content_blocks/', e.message);
					});
			}
		},
		pushCounters({commit}, countObject) {
			commit('pushCounters', {payload: countObject});
		},
		getPersonalManager({rootGetters}) {
			let {
				'cabinet/userInfo/_auth': _auth,
			} = rootGetters;

			return _laxios.getPersonalManager
				.request()
				.then((data) => data)
				.catch(e => {
                  console.error('/cabinet/api/personal_manager/', e.message);
				});
		},
		sendMessage({rootGetters}, payload) {
			let {
				'cabinet/userInfo/_auth': _auth,
			} = rootGetters;

			return _laxios.sendMessage
				.request({data: payload})
				.then((data) => data)
				.catch(e => {
                  console.error('/cabinet/api/personal_manager/', e.message);
				});
		},
	},
	mutations: {
		pushCounters(state, {payload}) {
			let entries = keyValue(payload);
			if (Object.keys(entries).length) {
				for (let [key, value] of Object.entries(entries)) {
					Vue.set(state.counters, key, value);
				}
			}
			if (payload.child) {
				payload.child.map((childItem) => {
					entries = keyValue(childItem);
					if (Object.keys(entries).length) {
						for (let [key, value] of Object.entries(entries)) {
							Vue.set(state.counters, key, value);
						}
					}
				});
			}
		},
		leftBar(state, {payload}) {
			let result = {};
			for (let key in payload) {
				if (payload.hasOwnProperty(key)) {
					Object.assign(result, keyValue(payload[key]));
					if (payload[key].child) {
						payload[key].child.map((childItem) => {
							Object.assign(result, keyValue(childItem));
						});
					}
					Vue.set(state.data, key, payload[key]);
				}
			}
		},
		/**
		 * Методы для динамического обновления счетчиков на СПА
		 */
		decrementEmergencyCnt(state) {
			Vue.set(state, 'counters.NotificationsEmergency.count', state.counters.NotificationsEmergency.count--);
			Vue.set(state, 'counters.Notifications.count', state.counters.Notifications.count--);
		},
		decrementInformingCnt(state) {
			Vue.set(state, 'counters.NotificationsInforming.count', state.counters.NotificationsInforming.count--);
			Vue.set(state, 'counters.Notifications.count', state.counters.Notifications.count--);
		},
		decrementImportantCnt(state) {
			Vue.set(state, 'counters.NotificationsImportant.count', state.counters.NotificationsImportant.count--);
			Vue.set(state, 'counters.Notifications.count', state.counters.Notifications.count--);
		},
		incrementArchiveAdvCnt(state) {
			if (state.counters.MyAdvertisementsArchive === undefined) {
				state.counters.MyAdvertisementsArchive = {count: 0};
			}
			Vue.set(state, 'counters.MyAdvertisementsArchive.count', state.counters.MyAdvertisementsArchive.count++);
		},
		decrementAdvCntByName(state, name) {
			if (state.counters[name] && state.counters[name].count > 0) {
				Vue.set(state, `counters.${name}.count`, state.counters[name].count--);
			}
		},
		makeAllViewed(state) {
		  Vue.set(state, 'counters', {
			NotificationsEmergency: {
			  count: 0
			},
			NotificationsInforming: {
			  count: 0
			},
			NotificationsImportant: {
			  count: 0
			},
			Notifications: {
			  count: 0
			}
		  });
		}
	},
	getters: {
		leftBar({data}) {
			return data;
		},
		counters({counters}) {
			return counters;
		},
		notificationCounters({counters: {NotificationsEmergency, NotificationsInforming, NotificationsImportant}}) {
			return {NotificationsEmergency, NotificationsInforming, NotificationsImportant};
		}
	}
};
