import Vue from 'vue';
import _laxios from '../../_laxios';

const {cabinet: {balance} = {}} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		data: []
	}, balance),
	actions: {
		balance({commit, getters, rootGetters}, Cookie) {
			let {'cabinet/userInfo/_auth': _auth} = rootGetters;
		  	let {'lang/id': langId} = rootGetters;
		  	const params = {langId};
			let headers = {};
			let sendRequest = false;
			if (Cookie) {
				headers.Cookie = Cookie;
			}

			if (_TARGET_ === 'client') {
				if (!Object.keys(getters.balance).length) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.balance
					.request({headers, params})
					.then(payload => {
						commit('balance', {payload});
					})
					.catch(e => {
                      console.error('/cabinet/api/v2/main_page/balance/', e.message);
					});
			}
		}
	},
	mutations: {
		balance(state, {payload}) {
			for (let key in payload) {
				if (payload.hasOwnProperty(key)) {
					Vue.set(state.data, key, payload[key]);
				}
			}
		}
	},
	getters: {
		balance({data}) {
			return data;
		},
	  	account({data}) {
			return data.find((item) => item.name === 'account') || {};
		},
	  	publications({data}) {
		  return data.find((item) => item.name === 'publication') || {};
		},
		promo({data}) {
		  return data.find((item) => item.name === 'promo') || {};
		}
	}
};
