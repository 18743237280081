import Vue from 'vue';
import _laxios from '../../_laxios';

const {cabinet: {alerts} = {}} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		data: {}
	}, alerts),
	actions: {
		alerts({commit, rootGetters, getters}) {
			let {'lang/id': langId} = rootGetters;
			let {'cabinet/userInfo/_auth': _auth} = rootGetters;
			let data = {
				headers: {}
			};
			let sendRequest = false;

			data.params = {
				langId: langId
			};

			if (_TARGET_ === 'client') {
				if (!Object.keys(getters.alerts).length) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.alerts
					.request(data)
					.then(payload => {
						commit('alerts', {payload});
					})
					.catch(e => {
                        console.error('/cabinet/api/reminders/', e.message);
					});
			}
		}
	},
	mutations: {
		alerts(state, {payload}) {
			Vue.set(state, 'data', payload);
		}
	},
	getters: {
		alerts({data}) {
			return data;
		}
	}
};
