import Vue from 'vue';
import _laxios from '../../_laxios';

const {cabinet: {subscriptions} = {}} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		data: {}
	}, subscriptions),
	actions: {
		subscriptions({commit, rootGetters, getters}, Cookie) {
			let {'cabinet/userInfo/_auth': _auth} = rootGetters;
			let {'lang/id': langId} = rootGetters;
			let data = {
				headers: {}
			};
			let sendRequest = false;

			if (Cookie) {
				data.headers.Cookie = Cookie;
			}
			data.params = {
				langId: langId,
				limit: 2
			};

			if (_TARGET_ === 'client') {
				if (!Object.keys(getters.subscriptions).length) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.getSubscribePageInfo
					.request(data)
					.then(payload => {
						commit('subscriptions', {payload});
					})
					.catch(e => {
                      console.error('subscriptions/subspage fetchSubscribe error', e.message);
					});
			}
		}
	},
	mutations: {
		subscriptions(state, {payload}) {
			Vue.set(state, 'data', payload);
		}
	},
	getters: {
		subscriptions({data}) {
			return data;
		}
	}
};
