import Vue from 'vue';
import _laxios from '../../_laxios';

const {cabinet: {statisticCounters} = {}} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		data: {}
	}, statisticCounters),
	actions: {
		statisticCounters({commit, getters}, Cookie) {
			let data = {};
			let sendRequest = false;
			if (Cookie) {
				data = {
					headers: {}
				};
				data.headers.Cookie = Cookie;
			}

			if (_TARGET_ === 'client') {
				let counters = getters.statisticCounters;
				if (!Object.keys(counters).length) {
					sendRequest = true;
				} else {
					for (let key in counters) {
						if (!Object.keys(counters[key]).length) {
							sendRequest = true;
						}
					}
				}
			} else {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.statisticCounters
					.request(data)
					.then(payload => commit('statisticCounters', {payload}))
					.catch(e => {
						console.log('[USER_MENU API] statisticCounters (/cabinet/api/main_page/statistic) data error:',
							e.message);
					});
			}
		}
	},
	mutations: {
		statisticCounters(state, {payload}) {
			Vue.set(state, 'data', payload);
		}
	},
	getters: {
		statisticCounters({data}) {
			return data;
		}
	}
};
