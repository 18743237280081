import _laxios from '../../_laxios';
import Vue from 'vue';

const {cabinet: {tags} = {}} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		data: {}
	}, tags),
	actions: {
		tags({commit, getters, rootGetters}, {noCache, cookies}) {
			let {'cabinet/userInfo/_auth': _auth} = rootGetters;
			let data = {
				headers: {}
			};

			data.params = {
				noCache: Number(noCache)
			};

			if (cookies) {
				data.headers.Cookie = cookies;
			}

			return _laxios.tags
				.request(data)
				.then(payload => {
					commit('tags', {payload});
				})
				.catch(e => {
                    console.error('/users/tags/', e.message);
				});
		}
	},
	mutations: {
		tags(state, {payload}) {
			Vue.set(state, 'data', payload);
		}
	},
	getters: {
		tags({data}) {
			return data && data.auto && data.auto.userOptions || {};
		}
	}
};
